
import {nextQuestion, addToQuizAnswers} from '../../Store/mainSlice';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {setToForm} from '../../Store/mainSlice'
import './QuizFreeText.css';

export function QuizFreeText(props){

    const {question} = props;
    const [answer, setAnswer] = useState('Type Answer Here...');
    const currentQuestion = useSelector(state => state.main.question);
    const dispatch = useDispatch();

    const handleSubmit = (evt) => {
        evt.preventDefault();
        dispatch(addToQuizAnswers({answer: answer, question: question}));       
         if(currentQuestion ===8){
            //TODO logic for backend submission
            dispatch(setToForm())
        }else{
            dispatch(nextQuestion());
        }
    }

    return(
        <form
        className="freetextContainer"
        onSubmit={handleSubmit}
         >
            <textArea
            className='textArea'
            value={answer}
            onClick={e => setAnswer('')}
            onChange={e=> setAnswer(e.target.value)}
            >
            </textArea>
            <br/>
            <br/>
            <input className= 'submitButton' type= 'submit' value= '->'/>
        </form>

    )

}