export const QuizAPI = {

    //if answers' length is 0, then render a textArea
    1:{
        question: `    Where was the murder?` , 
        answers:['Hallway', 
        'Laboratory', 
        'Parking garage', 
        'Rooftop',
        'Cubicle'],
    },
    2:{
        question: `Did you see any Mothmen?` , 
        answers:['Yes', 'No'],
    },
    3:{
        question: `Where are we?` , 
        answers:[ 
                'New York City',
                'Los Angeles',
                'Milan',
                'London',
                'Ontario',
                ],
    },
    4:{
        question: `What was your experience of The Dark?` , 
        answers:[],
    },
    5:{
        question: `Is it safe to drink the water?` , 
        answers:[
                'Yes',
                'No',
                'Only on Tuesdays'
        ],
    },
    6:{
        question: `What do all the videos have in common?` , 
        answers:[],
    },
    7:{
        question: `Which employee isn’t real?` , 
        answers:[
                'Dylan',
                'Travis',
                'Annie',
                'Kip',
                'Javier',
                'Judith'
                ],
    },
    8:{
        question: `What is the result of the experiments?`,
        answers:[]
    },
    9:{
        question:'',
        answers:[]
    }
}
