import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    page:'video',
    modalOpen: false,
    modalSrc:'',
    question:'1',
    quizAnswers: {},
    codeName:'',
}

export const mainSlice = createSlice({
    name:'mainSlice',
    initialState,
    reducers:{
        setToVideo: state => {
            state.page = 'video';
        },
        setToQuiz: state => {
            state.page = 'quiz'
        },
        setToForm: state =>{
            state.page = 'form'

        },
        setToFinal: state => {
            state.page='final'
        },
        setModalOpen: (state,action) => {
            state.modalOpen = action.payload
        },
        setModalSrc: (state, action) => {
            state.modalSrc=action.payload
        },
        nextQuestion: (state) => {
            state.question++;
        },
        addToQuizAnswers: (state, action) => {
            state.quizAnswers[`${action.payload.question}`] = `${action.payload.answer}`;
        },
        setCodeName: (state,action) =>{
            state.codeName=action.payload;
        }
    }
});

export const {setToFinal, setToQuiz, setToVideo, setModalOpen, setModalSrc, addToQuizAnswers, nextQuestion, setCodeName, setToForm} = mainSlice.actions;

export default mainSlice.reducer;