const md5 = require("md5");

export const sha256Passcode =async(passcode) =>{
    const {createHmac} =await import('crypto');

    const secret = process.env.sha256Key;
    const hash = createHmac("sha256", secret).update(passcode).digest("hex");
    return hash;

}

export const md5Hash = (value) => {
    return md5(value);
  };