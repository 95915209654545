import {Videos} from '../Organisms';
import {ModalVid, VideoButton} from '../Atoms'
import {useState, useEffect} from 'react';
import './VideoTemplate.css'

export function VideoTemplate(props){

    const{axios} = props;

    const [loading, setLoading] = useState(true);
    useEffect(()=> {
        setTimeout(()=>setLoading(false),5000);
    },[]);
    return(
        <div className='mainContainer'>
            <ModalVid/>
            <p>{loading ? <div className ="loading">Connecting</div> : 'PAY ATTENTION'}</p>
            <div className='videoContainer'>
                <Videos/>
                <VideoButton axios={axios} text={`I've seen it all.`}/>

            </div>
        </div>
    )
}

