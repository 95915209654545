import './Video.css'
import ReactPlayer from "react-player"
import {LoadingAnim} from '../Atoms'

export function Video(props){
    const {videoSrc} = props;



    return(
        <div className= 'video' >
             <div className='click'/>
             <ReactPlayer fallback={<LoadingAnim/>} width={'100%'} height={'100%'} url={videoSrc} playing={true} muted={true} loop={true} playsinline={true}/>
        </div>
    )

}