import {sha256Passcode} from '../HashHelper';
import env from "react-dotenv";

export function fbPixelEvent(name, date) {

    if(typeof window !== 'undefined'){
        if(window.fbq != null){
            window.fbq('trackCustom', `${name}`, {eventID: `${date}`});
        }
    }
}


export  async function fbConversionAPIPost(axios, payload, date) {
    const fbKey= env?.FbConversionKey;
    const pixelID= env?.PixelID
    const fbConversionURL= `https://graph.facebook.com/v13.0/${pixelID}/events?access_token=${fbKey}`;

    
    const currentTimeInSeconds = date.getSeconds()/1000;
    payload.data[0].event_time=currentTimeInSeconds;
    payload.data[0].event_id=date;

    if(payload?.data[0]?.user_data?.em){
        payload.data[0].user_data.em = sha256Passcode(payload.data[0].user_data.em);
    }
    if(payload?.data[0]?.user_data?.ph){
        payload.data[0].user_data.ph = sha256Passcode(payload.data[0].user_data.ph);
    }
    if(payload?.data[0]?.user_data?.fn){
        payload.data[0].user_data.fn = sha256Passcode(payload.data[0].user_data.fn)
    }
    if(payload?.data[0]?.user_data?.ln){
        payload.data[0].user_data.ln = sha256Passcode(payload.data[0].user_data.ln)
    }


    await axios.post(fbConversionURL, payload)
        .catch((error)=>{
            if(error.response){
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            }else if(error.request){
                console.log('NO RESPONSE', error.request);
            }else{
                console.log(error.message);
            }
        });
}

//use this to have proper deduplication 
export async function fbTrackingEvent(name, axios, payload){
    const currentDate = new Date();
    await fbConversionAPIPost(axios, payload, currentDate);
    await fbPixelEvent(name, currentDate);

}



