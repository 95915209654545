import {QuizAPI} from '../../QuizAPI'
import { useSelector } from 'react-redux';
import {QuizQuestion} from '../Molecules';

export function Quiz(){

    const currentQuestion = useSelector(state => state.main.question);
    const {question, answers} = QuizAPI[currentQuestion];

  

    return(
    <div className='quizContainer'>
        <QuizQuestion question={question} answers = {answers}/>
    </div>
    )
}