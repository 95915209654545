
import {addToQuizAnswers, nextQuestion} from '../../Store/mainSlice'
import {QuizFreeText} from '../Atoms';
import { useDispatch } from 'react-redux';
import './QuizQuestion.css';

export function QuizQuestion(props){

    const {question, answers} = props;
    const dispatch = useDispatch();


    return(
    <div className= 'quizQuestionContainer'>
        <p className='quizQuestion'>{question}</p>
        {(answers.length > 0) ? (
            answers.map( (answer) =>
            <button
                className='quizAnswer'
                onClick={()=>{
                    dispatch(addToQuizAnswers({answer: answer, question: question}));
                    dispatch(nextQuestion());
                }}
            >
                {answer}
            </button>  
            )
        ) :(
          <QuizFreeText question={question}/>
            
        )
          
        }
    </div>
    );

}