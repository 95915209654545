import './button.css'
import { useDispatch } from 'react-redux';
import {setToQuiz} from '../../Store/mainSlice'
import {useState, useEffect} from 'react';
import {fbTrackingEvent} from '../../Functions';

export function VideoButton(props){
    const {text, axios} = props;
    const dispatch= useDispatch();

    const [visible, setVisible] = useState(false);

    const camQuizStartPayload = {
        "data": [
            {
                "event_name": "camQuizStart",
                "event_time": null,
                "action_source": "app",
                "event_id": null
            }
        ]
    };

    useEffect(()=>{
        setTimeout(()=> setVisible(true),10000);
    
    },[])

    const handleClick = async() => {
        try{
            await fbTrackingEvent('camQuizStart', axios, camQuizStartPayload)
       
        dispatch(setToQuiz());
        }catch(err){
            console.log(err);
        }
    }

    return(
        <div onClick={(handleClick)} className={visible ? 'videoButton' : 'hidden'}>{text}</div>
    )


}