import {useDispatch} from 'react-redux'
import {Video} from '../Atoms';
import {setModalOpen, setModalSrc} from '../../Store/mainSlice'


export function Video_Modal(props){

const {videoSrc, embededSrc } = props;
const dispatch = useDispatch();
const handleClick= () => {
     dispatch(setModalOpen(true));
     dispatch(setModalSrc(embededSrc));
}
return(
    <div onClick={handleClick} className='ye'>
        <Video  videoSrc={videoSrc} videoClass='video'/>
    </div>
)


} 