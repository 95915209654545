import './LoadingSpinner.css'

export function LoadingSpinner(props){

const {loading} = props;

return(
    <div className = { loading ? 'spinner' : 'none'}>
    </div>
)

}