
import {useSelector} from 'react-redux'
import {VideoTemplate, QuizTemplate, FinalTemplate, FormTemplate} from '../Templates'
import './mainPage.css';
import env from "react-dotenv";
import axios from 'axios'

export function MainPage(){

const page = useSelector(state=> state.main.page)
const baseURL = env?.BASE_URL.replaceAll('"', '') ? env?.BASE_URL : process.env.BASE_URL;
let ax = null;


if(axios?.create){
 ax = axios.create({
    baseURL: baseURL,
    headers:{
      common:{
        x_api_key: 'ya'
      }
    }
  }
  );
}


if(page ==='video'){
    return(
        <VideoTemplate axios={ax} className='font-face-GT'/>
    )
}else if(page ==='quiz'){
    return(
        <QuizTemplate className='font-face-GT'/>
    )
}else if(page==='form'){
    return(
        <FormTemplate axios={ax}/>
    )
}else{
    return(
        <FinalTemplate className='font-face-GT'/>
    )
}
}