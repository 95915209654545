import {IndividualForm} from '../Molecules'
import './FormTemplate.css'

export function FormTemplate(props){

    const {axios} = props;
    return(
        <div className='formContainer'>
            <h4 className='formText'>COMPLETE THIS FORM</h4>
    <br></br>
            <p className='formText'>You have just completed Phase 1 of our 3-phase screening process. Your performance is promising.</p>
            <p className='formText'>
    Next, you will receive a unique, three-word passcode to your email. This passcode will grant access to Phase 2 of agent screening.
    </p><br></br><br></br>
            <IndividualForm axios={axios} />
        </div>
    )
}