
import './Videos.css'
import {Video_Modal} from '../Molecules';

export function Videos(){

const videoData = [
    {
        embededSrc: 'https://player.vimeo.com/video/684822908?h=edb8775339&amp',
        videoSrc: 'https://vimeo.com/672142688'
    },
    {
        embededSrc: 'https://player.vimeo.com/video/671772723?h=08881fdfaf&amp',
        videoSrc: 'https://vimeo.com/684822148'
    },
    {
        embededSrc: 'https://player.vimeo.com/video/684822156?h=8b4e06b307&amp',
        videoSrc: 'https://vimeo.com/684822156'
    },
    {
        embededSrc: 'https://player.vimeo.com/video/684823209?h=ba67380048&amp',
        videoSrc: 'https://vimeo.com/684823209'
    },
   
    {
        embededSrc: 'https://player.vimeo.com/video/684823014?h=754f6ac208&amp',
        videoSrc: 'https://vimeo.com/684823014'
    },
    {
        embededSrc: 'https://player.vimeo.com/video/671769091?h=bfdbc4739e&amp',
        videoSrc: 'https://vimeo.com/671769091'
    },
    {
        embededSrc: 'https://player.vimeo.com/video/684823126?h=0dcfd86c0d&amp',
        videoSrc: 'https://vimeo.com/684823126'
    },
  
    {
        embededSrc: 'https://player.vimeo.com/video/684822204?h=cd3c07723d&amp',
        videoSrc: 'https://vimeo.com/684822204'
    },

]



    return(
        <div className = 'videoGrid'>
            {videoData.map((data)=>{
                return(
                    <Video_Modal videoSrc={data.videoSrc} embededSrc={data.embededSrc}/>
                )
            })}
        </div>
    )
}