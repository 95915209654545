 import {Quiz} from '../Organisms'
 import './QuizTemplate.css'
 
export function QuizTemplate() {

    return(
    <div className='mainContainer'>
        <Quiz/>
    </div>
    )



}