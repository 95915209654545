import './FinalTemplate.css'
import {useEffect} from 'react';

export function FinalTemplate(){

   

return (
    <div className = 'finalContainer'>
        <div className='finalText'>
        <h3 className='congrats'>Congratulations you passed. <br/> Wait to be contacted by us. <br/> We will be watching.  </h3>
        </div>
    </div>
)



}