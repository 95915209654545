import {useFormik, Formik, Form, Field, ErrorMessage,} from 'formik';
import {ContactFormSchema} from './FormSchema';
import {setToFinal, setCodeName} from '../../Store/mainSlice';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import {useState} from 'react'
import './Form.css'
import env from 'react-dotenv';
import {LoadingSpinner} from  '../Atoms'


export function IndividualForm() {

    const quizAnswers = useSelector(state=>state.main.quizAnswers)
    const dispatch = useDispatch();
    const baseURL = env?.BASE_URL.replaceAll('"', '') ? env?.BASE_URL : process.env.BASE_URL
    const [processable, setProcessable] = useState(true);

    const ax = axios.create({
      baseURL: baseURL,
      headers:{
        common:{
          x_api_key: 'ya'
        }
      }
    }
    )

    const validateCheckbox = (value) => {
      let error;
      if (!value) {
        error = 'You must check the checkbox to submit the form';
      }
      return error;
    };

    return(
    <Formik 
    initialValues ={{
      fullname:'',
      email:'',
      phone: '',
      checkbox: false,
    }}
    validationSchema={ContactFormSchema}

    //This really should use thunks, but pressed for time. 
    onSubmit = {async(values, {setErrors}) => {
   
      try{
        if(processable){
        await setProcessable(false);
        const payload = {...values, quiz:{results: quizAnswers}}
        await ax.post(`${baseURL}/user/trial`, payload ).then( async(response) => {
            await dispatch(setCodeName(response.data.data.passcode));
            await dispatch(setToFinal());
            console.log(JSON.stringify(response.data.errors.code));
            if (JSON.stringify(response.data.errors.code) === 1062) {
             setErrors({phone: "This phone number is already in use."})
            }
        });
         window.location.href = "https://onboarding2.unshut.me";
      }
      }catch(err){
        await setProcessable(true);
         
      }
    }}
    >
    {({ errors, touched }) => (     
        <Form>
          <div>
            <Field className='textInput' placeholder='verify your name' size="40" name="fullname" type="text" />
            {errors.fullname && touched.fullname? (<div className='error'>{errors.fullname}</div>) : null }
            </div>


            <div>
            <Field className='textInput' placeholder='enter an email address' size="27" name="email" type="text" />
              {errors.email && touched.email ? (<div className='error'>{errors.email}</div>) : null }
            </div>

            <div>
            <Field className='textInput' placeholder='enter a cell phone number' size="27" name="phone" type="text" />
              {errors.phone && touched.phone ? (<div className='error'>{errors.phone}</div>) : null }
              <br/>
              <Field  name="checkboxInput" validate={validateCheckbox}>
            {({ field, form }) => (
              <div>
                <input
                  type="checkbox"
                  {...field}
                />
                <label className='regularText' htmlFor="checkbox">UNSHUT can contact me about this experience. See full <a href = "https://unshut.me/termsconditions">Terms and Conditions</a> and <a href = "https://unshut.me/privacy-policy">Privacy Policy</a></label>
                <ErrorMessage name="error" component="div" />
              </div>
            )}
          </Field>
              <div className="submitButtonContainer">
                <button className='button' type='submit'>SUBMIT</button>
                <LoadingSpinner loading={!processable}/>
              </div>
            </div>   
        </Form>
      )}
    </Formik>
    )
}