

import { useSelector, useDispatch } from "react-redux";
import { IoCloseOutline } from "react-icons/io5";
import { BiLoaderAlt } from "react-icons/bi";
import {setModalOpen, setModalSrc} from '../../Store/mainSlice'
import { useState } from "react";
import './Video.css';

export function ModalVid(){

const dispatch= useDispatch();

const modalOpen = useSelector(state => state.main.modalOpen)
const modalSource = useSelector(state => state.main.modalSrc);

const [videoLoading, setVideoLoading] = useState(true);

const spinner = () => {
    setVideoLoading(!videoLoading);
  };

const setModal = async() => {
    await dispatch(setModalOpen(false));
    await dispatch(setModalSrc(''));
  }
const autoplay = `background=1&autoplay=1&;title=0&;byline=0&;portrait=0&;loop=1&;autopause=0&;muted=0" width="640" height="360" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""`;

return (
    <div className="App">
        {modalOpen ? (
          <section className="modal__bg">
            <div className="modal__align">
              <div className="modal__content" modal={modalOpen}>
                <IoCloseOutline
                  className="modal__close"
                  arial-label="Close modal"
                  onClick={setModal}
                />
                <div className="modal__video-align">
                  {videoLoading ? (
                    <div className="modal__spinner">
                      <BiLoaderAlt
                        className="modal__spinner-style"
                        fadeIn="none"
                      />
                    </div>
                  ) : null}
                  <iframe
                    className="modal__video-style"
                    onLoad={spinner}
                    loading="lazy"
                    width="500"
                    height="500"
                    src= {modalSource+autoplay}
                    title="Vimeo video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; playsInline; loop; muted; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  ></iframe>
                </div>
              </div>
            </div>
          </section>
        ) : null}
    </div>
  );
}